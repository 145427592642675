import 'whatwg-fetch';
import 'core-js/es/object/assign';
import 'core-js/es/object/entries';
import 'core-js/es/object/values';
import 'core-js/es/promise';
import 'core-js/es/map';
import 'core-js/es/symbol/for';
import 'core-js/es/string/pad-start';
import 'core-js/es/string/ends-with';
import 'core-js/es/array/find-index';
import 'core-js/es/array/find';
import 'core-js/es/array/from';
import 'core-js/es/array/fill';
import 'core-js/es/number/is-nan';

async function getIntl() {
  return Promise.all([
    await import(/* webpackChunkName: "intl" */ 'intl'),
    await import(
      /* webpackChunkName: "intl" */ 'intl/locale-data/jsonp/en.js' as any
    ),
  ]);
}

if (!window.Intl) {
  getIntl();
}
